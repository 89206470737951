function getSrcSet({ sources }) {
  let srcSet = "";
  for (let source of sources) {
    srcSet += `${source.url} ${source.width}w`;
    if (source.width < sources[sources.length - 1].width) {
      srcSet += ", ";
    }
  }
  return srcSet;
}

export default function Picture({
  media,
  file,
  sizes = "100vw",
  priority = false,
  rendering = "auto",
  className = "",
}) {
  let { kind = "image", sources, alt = "" } = media || {};

  if (!sources && file) {
    sources = [file];
    kind = file.kind || "image";
    alt = file.alt || "";
  }

  if (!sources || !sources.length) {
    return null;
  }

  if (kind !== "image") {
    // TODO: Try to show poster for videos
    return null;
  }

  // TODO: Get sources from media_id or use query params to deliver alt formats
  // TODO: If ends in .svg, use <img> instead of <picture>
  let types = [
    "image/webp",
    "image/avif",
    "image/heic",
    "image/heif",
    "image/gif",
    "image/png",
    "image/jpeg",
  ];

  let defaultSource = sources.find((source) => source.original === true);
  if (!defaultSource) {
    defaultSource = sources.sort((a, b) => a.created - b.created)[0];
  }

  if (sources.length === 1) {
    return (
      <picture>
        <img
          src={defaultSource.url}
          width={defaultSource.width}
          height={defaultSource.height}
          loading={priority ? "eager" : "lazy"}
          alt={alt}
          className={className}
        />
      </picture>
    );
  }

  return (
    <picture>
      {types.map((type) => {
        let filteredSources = sources
          .filter((source) => source.type === type)
          .sort((a, b) => a.width - b.width);
        if (filteredSources.length) {
          return (
            <source
              key={type}
              srcSet={getSrcSet({ sources: filteredSources })}
              type={type}
            />
          );
        }
      })}
      <img
        srcSet={getSrcSet({
          sources: sources
            .filter((source) => source.type === defaultSource.type)
            .sort((a, b) => a.width - b.width),
        })}
        src={defaultSource.url}
        width={defaultSource.width}
        height={defaultSource.height}
        sizes={sizes}
        alt={alt}
        loading={priority ? "eager" : "lazy"}
        decoding={priority ? "auto" : "async"}
        fetchPriority={priority ? "high" : "low"}
        style={{
          imageRendering: rendering,
        }}
        className={className}
      />
    </picture>
  );
}
