import Icon from "./Icon";

export default function Diff({ remove, add, size = "md" }) {
  let fontSize = "text-sm md:text-base";
  let iconSize = "w-4 h-4 my-1 ml-2";
  let rounded = "rounded-md";
  let padding = "pl-8 pr-2 py-1";
  if (size === "sm") {
    fontSize = "text-xs md:text-sm";
    iconSize = "w-4 h-4 ml-2 my-2";
    rounded = "rounded-md";
    padding = "pl-8 pr-2 md:pr-2 pb-1.5 pt-1.5";
  }
  if (size === "lg") {
    fontSize = "text-sm md:text-base lg:text-lg";
    iconSize = "w-4 h-4 md:w-5 md:h-5 lg:w-6 lg:h-6 ml-2 my-2";
    rounded = "rounded-lg";
    padding = "pl-8 pr-2 md:pl-10 md:pr-2 py-1 md:py-2";
  }
  if (size === "xl") {
    fontSize = "text-base md:text-lg";
    iconSize = "w-4 h-4 md:w-5 md:h-5 lg:w-6 lg:h-6 ml-2 my-2 md:my-3 lg:my-2";
    rounded = "rounded-lg";
    padding = "pl-8 pr-2 md:pl-10 md:pr-2 py-1 md:py-2";
  }

  return (
    <div
      className={`flex flex-col gap-0 ${rounded} overflow-hidden ${fontSize}`}
    >
      <code
        className={`relative max-w-full whitespace-normal text-left ${padding} font-code bg-brand-magenta/20 text-white/80`}
      >
        <Icon
          icon="minus"
          className={`absolute top-0 left-0 ${iconSize} text-brand-magenta`}
        />
        {remove}
      </code>
      <code
        className={`relative max-w-full whitespace-normal text-left ${padding} font-code bg-brand-green/20 text-white/80`}
      >
        <Icon
          icon="add"
          className={`absolute top-0 left-0 ${iconSize} text-brand-green`}
        />
        {add}
      </code>
    </div>
  );
}
